<script setup>
import { Link } from '@inertiajs/vue3';
import { FileAddIcon } from "@/Components/Icons";
import { XMarkIcon, ChatBubbleBottomCenterTextIcon, EnvelopeOpenIcon, UserPlusIcon } from '@heroicons/vue/24/outline';
import DiceClasse from "@/Pages/Analyses/Modules/DiceClasse.vue";
import DangerButton from "@/Components/DangerButton.vue";

const props = defineProps({
    notifications: Object,
});

const emit = defineEmits(["deleteNotif"]);

const deleteNotification = (id) => {
    emit("deleteNotif", id);
};

</script>

<template>
    <div class="px-4 py-1" v-for="(info, date) in notifications" :key="date">
        <p class="font-semibold">{{ date }}</p>
        <div class="text-sm my-2" v-for="(values, key) in info" :key="key">

            <div class="grid grid-cols-12 gap-1" v-if="values.notifType == 'App\\Notifications\\VariantClassification'">
                <div class="col-span-1 justify-self-center self-center">
                    <DiceClasse :classe="parseInt(values.classe)" type=""></DiceClasse>
                </div>
                <div class="col-span-10">
                    <span v-if="values.consensus">{{ __('A consensus classification has been established for the variant') + " " }}</span>
                    <span v-else>{{ __('A user established a classification for the variant') + " " }}</span>
                    <Link :href="route('variant.show', values.id)" class="text-gray-700 hover:text-theme-500 cursor-pointer">
                        CANDV{{ values.id + " " + values.chromosome_hg38 + ":" + values.position_hg38 + " " + values.reference_hg38 + "/" + values.observed_hg38 }}
                    </Link>
                    (GRCh38)
                </div>
                <div class="col-span-1 justify-self-center self-center">
                    <DangerButton class="!p-0" @click="deleteNotification(values.notifId)">
                        <XMarkIcon class="h-4 w-4" />
                    </DangerButton>
                </div>
            </div>

            <div class="grid grid-cols-12 gap-1" v-else-if="values.notifType == 'App\\Notifications\\VariantClassificationUpdate'">
                <div class="col-span-1 justify-self-center self-center text-center">
                    <DiceClasse :classe="parseInt(values.old_classe)" type=""></DiceClasse>
                    <span class="text-xs">&darr;</span>
                    <DiceClasse :classe="parseInt(values.new_classe)" type=""></DiceClasse>
                </div>
                <div class="col-span-10">
                    <span v-if="values.consensus">{{ __('The consensus classification has been updated for the variant') + " " }}</span>
                    <span v-else>{{ __('A user updated the classification for the variant') + " " }}</span>
                    <Link :href="route('variant.show', values.id)" class="text-gray-700 hover:text-theme-500 cursor-pointer">
                        CANDV{{ values.id + " " + values.chromosome_hg38 + ":" + values.position_hg38 + " " + values.reference_hg38 + "/" + values.observed_hg38 }}
                    </Link>
                    (GRCh38)
                </div>
                <div class="col-span-1 justify-self-center self-center">
                    <DangerButton class="!p-0" @click="deleteNotification(values.notifId)">
                        <XMarkIcon class="h-4 w-4" />
                    </DangerButton>
                </div>
            </div>

            <div class="grid grid-cols-12 gap-1" v-if="values.notifType == 'App\\Notifications\\CnvClassification'">
                <div class="col-span-1 justify-self-center self-center">
                    <DiceClasse :classe="parseInt(values.classe)" type=""></DiceClasse>
                </div>
                <div class="col-span-10">
                    <span v-if="values.consensus">{{ __('A consensus classification has been established for the CNV') + " " }}</span>
                    <span v-else>{{ __('A user established a classification for the CNV') + " " }}</span>
                    <Link :href="route('cnv.show', values.id)" class="text-gray-700 hover:text-theme-500 cursor-pointer">
                        CANDC{{ values.id + " " + values.chromosome_hg38 + ":" + values.start_hg38 + "-" + values.end_hg38 + " " + __(values.type) }}
                    </Link>
                    (GRCh38)
                </div>
                <div class="col-span-1 justify-self-center self-center">
                    <DangerButton class="!p-0" @click="deleteNotification(values.notifId)">
                        <XMarkIcon class="h-4 w-4" />
                    </DangerButton>
                </div>
            </div>

            <div class="grid grid-cols-12 gap-1" v-else-if="values.notifType == 'App\\Notifications\\CnvClassificationUpdate'">
                <div class="col-span-1 justify-self-center self-center text-center">
                    <DiceClasse :classe="parseInt(values.old_classe)" type=""></DiceClasse>
                    <span class="text-xs">&darr;</span>
                    <DiceClasse :classe="parseInt(values.new_classe)" type=""></DiceClasse>
                </div>
                <div class="col-span-10">
                    <span v-if="values.consensus">{{ __('The consensus classification has been updated for the CNV') + " " }}</span>
                    <span v-else>{{ __('A user updated the classification for the CNV') + " " }}</span>
                    <Link :href="route('cnv.show', values.id)" class="text-gray-700 hover:text-theme-500 cursor-pointer">
                        CANDC{{ values.id + " " + values.chromosome_hg38 + ":" + values.start_hg38 + "-" + values.end_hg38 + " " + __(values.type) }}
                    </Link>
                    (GRCh38)
                </div>
                <div class="col-span-1 justify-self-center self-center">
                    <DangerButton class="!p-0" @click="deleteNotification(values.notifId)">
                        <XMarkIcon class="h-4 w-4" />
                    </DangerButton>
                </div>
            </div>

            <div class="grid grid-cols-12 gap-1" v-else-if="values.notifType == 'App\\Notifications\\PatientCreated'">
                <div class="col-span-1 justify-self-center self-center">
                    <FileAddIcon class="w-4 h-4 fill-green-700" />
                </div>
                <div class="col-span-10">
                    {{ __('The import of your :count patient(s) is finished', { 'count': values.count }) }}
                </div>
                <div class="col-span-1 justify-self-center self-center">
                    <DangerButton class="!p-0" @click="deleteNotification(values.notifId)">
                        <XMarkIcon class="h-4 w-4" />
                    </DangerButton>
                </div>
            </div>

            <div class="grid grid-cols-12 gap-1" v-else-if="values.notifType == 'App\\Notifications\\SendGoodProcessVCF'">
                <div class="col-span-1 justify-self-center self-center">
                    <FileAddIcon class="w-4 h-4 fill-green-700" />
                </div>
                <div class="col-span-10">
                    {{ __('The import of your VCF file :filename is finished', { 'filename': values.filename.length > 25 ? values.filename.slice(0, 25) + "..." : values.filename }) }}
                </div>
                <div class="col-span-1 justify-self-center self-center">
                    <DangerButton class="!p-0" @click="deleteNotification(values.notifId)">
                        <XMarkIcon class="h-4 w-4" />
                    </DangerButton>
                </div>
            </div>

            <div class="grid grid-cols-12 gap-1" v-else-if="values.notifType == 'App\\Notifications\\SendBadProcessVCF'">
                <div class="col-span-1 justify-self-center self-center">
                    <FileAddIcon class="w-4 h-4 fill-red-700" />
                </div>
                <div class="col-span-10">
                    {{ __('The import of your VCF file :filename failed', { 'filename': values.filename.length > 25 ? values.filename.slice(0, 25) + "..." : values.filename }) + ', ' + __(values.message)
                    }}
                </div>
                <div class="col-span-1 justify-self-center self-center">
                    <DangerButton class="!p-0" @click="deleteNotification(values.notifId)">
                        <XMarkIcon class="h-4 w-4" />
                    </DangerButton>
                </div>
            </div>

            <div class="grid grid-cols-12 gap-1" v-else-if="values.notifType == 'App\\Notifications\\ClasseMessageFromUser'">
                <div class="col-span-1 justify-self-center self-center">
                    <ChatBubbleBottomCenterTextIcon class="w-4 h-4 text-theme-500" />
                </div>
                <div class="col-span-10">
                    {{ __('An email has been sent to you from :from requesting information about the classification \"class :class\" in your analysis :name created on :date for the variant', {
                        from: values.from, class: values.classe, name: values.analyse_name, date: values.analyse_created_at
                    }) }}
                    <Link :href="route('variant.show', values.variant_id)" class="text-gray-700 hover:text-theme-500 cursor-pointer">
                        CANDV{{ values.variant_id + " " + values.chromosome_hg38 + ":" + values.position_hg38 + " " + values.reference_hg38 + "/" + values.observed_hg38 }}
                    </Link>
                    (GRCh38)
                </div>
                <div class="col-span-1 justify-self-center self-center">
                    <DangerButton class="!p-0" @click="deleteNotification(values.notifId)">
                        <XMarkIcon class="h-4 w-4" />
                    </DangerButton>
                </div>
            </div>

            <div class="grid grid-cols-12 gap-1" v-else-if="values.notifType == 'App\\Notifications\\ClasseCnvMessageFromUser'">
                <div class="col-span-1 justify-self-center self-center">
                    <ChatBubbleBottomCenterTextIcon class="w-4 h-4 text-theme-500" />
                </div>
                <div class="col-span-10">
                    {{ __('An email has been sent to you from :from requesting information about the classification \"class :class\" in your analysis :name created on :date for the CNV', {
                        from: values.from, class: values.classe, name: values.analyse_name, date: values.analyse_created_at
                    }) }}
                    <Link :href="route('cnv.show', values.cnv_id)" class="text-gray-700 hover:text-theme-500 cursor-pointer">
                        CANDC{{ values.cnv_id + " " + values.chromosome_hg38 + ":" + values.start_hg38 + "-" + values.end_hg38 + " " + __(values.type) }}
                    </Link>
                    (GRCh38)
                </div>
                <div class="col-span-1 justify-self-center self-center">
                    <DangerButton class="!p-0" @click="deleteNotification(values.notifId)">
                        <XMarkIcon class="h-4 w-4" />
                    </DangerButton>
                </div>
            </div>

            <div class="grid grid-cols-12 gap-1" v-else-if="values.notifType == 'App\\Notifications\\PatientMessageFromUser'">
                <div class="col-span-1 justify-self-center self-center">
                    <ChatBubbleBottomCenterTextIcon class="w-4 h-4 text-theme-500" />
                </div>
                <div class="col-span-10">
                    {{ __('An email has been sent to you from :from requesting information about the patient', { from: values.from }) }}
                    <Link :href="route('patient.show', values.patient_id)" class="text-gray-700 hover:text-theme-500 cursor-pointer">
                    CANDP{{ values.patient_id + " (" + values.patient_local_id + ")" }}
                    </Link>
                </div>
                <div class="col-span-1 justify-self-center self-center">
                    <DangerButton class="!p-0" @click="deleteNotification(values.notifId)">
                        <XMarkIcon class="h-4 w-4" />
                    </DangerButton>
                </div>
            </div>

            <div class="grid grid-cols-12 gap-1" v-else-if="values.notifType == 'App\\Notifications\\UpdateACMG'">
                <div class="col-span-1 justify-self-center self-center text-center">
                    <DiceClasse :classe="parseInt(values.oldClasse)" type=""></DiceClasse>
                    <span class="text-xs">&darr;</span>
                    <DiceClasse :classe="parseInt(values.newClasse)" type=""></DiceClasse>
                </div>
                <div class="col-span-10">
                    <span>{{ __('The precomputed ACMG classification has been updated for the variant') + " " }}</span>
                    <Link :href="route('variant.show', values.variant_id)" class="text-gray-700 hover:text-theme-500 cursor-pointer">
                    {{ values.variant }}
                    </Link>
                    (GRCh38)
                    <span v-if="values.patients">{{ __("The following patients have this variant: :patients", { 'patients': (JSON.parse(values.patients)).join(" ") }) }}</span>
                </div>
                <div class="col-span-1 justify-self-center self-center">
                    <DangerButton class="!p-0" @click="deleteNotification(values.notifId)">
                        <XMarkIcon class="h-4 w-4" />
                    </DangerButton>
                </div>
            </div>

            <div class="grid grid-cols-12 gap-1" v-else-if="values.notifType == 'App\\Notifications\\TeamInvitation'">
                <div class="col-span-1 justify-self-center self-center">
                    <EnvelopeOpenIcon class="w-4 h-4 text-theme-500" />
                </div>
                <div class="col-span-10">
                    <span>{{ __('You have been invited to join the :team team!', { 'team': values.team }) + " " }}</span>
                    <Link :href="values.acceptUrl" class="text-gray-700 hover:text-theme-500 cursor-pointer">
                    {{ __('Click here to accept') }}
                    </Link>
                </div>
                <div class="col-span-1 justify-self-center self-center">
                    <DangerButton class="!p-0" @click="deleteNotification(values.notifId)">
                        <XMarkIcon class="h-4 w-4" />
                    </DangerButton>
                </div>
            </div>

            <div class="grid grid-cols-12 gap-1" v-else-if="values.notifType == 'App\\Notifications\\AlertAdminNewUser'">
                <div class="col-span-1 justify-self-center self-center">
                    <UserPlusIcon class="w-4 h-4 text-theme-500" />
                </div>
                <div class="col-span-10">
                    <span>{{ __('A new user has registered and is waiting to be validated: :firstname :lastname.', { 'firstname': values.firstname, 'lastname': values.lastname }) + " " }}</span>
                    <Link :href="route('manage.user.edit', values.id)" class="text-gray-700 hover:text-theme-500 cursor-pointer">
                    {{ __('Click here to activate them') }}
                    </Link>
                </div>
                <div class="col-span-1 justify-self-center self-center">
                    <DangerButton class="!p-0" @click="deleteNotification(values.notifId)">
                        <XMarkIcon class="h-4 w-4" />
                    </DangerButton>
                </div>
            </div>

        </div>
    </div>
</template>
