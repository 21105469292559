<template>
    <svg viewBox="0 0 2487.4807 412" width="2487.4807" height="412" xml:space="preserve" overflow="hidden" version="1.1" id="svg43" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg">
        <defs id="defs5" />
        <g id="g392" transform="translate(-203.51074,-356.06781)">
            <g id="g288">
                <g id="g344">
                    <path d="m 1058.9915,363.0678 h 78.03 l 240.97,368.65 v 34.35 h -78.03 l -240.97,-364.07 z" fill-rule="evenodd" fill-opacity="1" id="path7" />
                    <path d="m 1299.9915,361.0678 v 64.05 l 78,114.95 v -177.36 z" fill-rule="evenodd" fill-opacity="1" id="path9" />
                    <path d="m 1135.9915,768.0678 v -64.05 l -77,-114.95 v 177.36 z" fill-rule="evenodd" fill-opacity="1" id="path11" />
                    <path d="m 1542.4515,361.1378 c 136.92,-2.94 250.84,85.08 254.45,196.6 3.62,111.53 -104.45,204.32 -241.37,207.26 -2.18,0.05 -4.36,0.07 -6.53,0.07 l -0.01,-77.24 c 94.31,0 170.76,-55.86 170.76,-124.76 0,-68.91 -76.46,-124.76 -170.76,-124.76 -1.35,0 -2.69,0.01 -4.04,0.04 z" fill-rule="evenodd" fill-opacity="1" id="path13" />
                    <path d="m 1549.9915,765.0678 h -83 v -221 l 81.31,1.7 z" fill-rule="evenodd" fill-opacity="1" id="path15" />
                    <path d="m 1549.9915,438.3378 -81,1.73 v -78 l 78.94,-1.02998 z" fill-rule="evenodd" fill-opacity="1" id="path17" />
                    <rect x="1845.9916" y="361.06781" width="79" height="404" fill-opacity="1" id="rect19" />
                    <rect x="2432.9915" y="361.06781" width="258" height="79.000099" fill-opacity="1" id="rect21" />
                    <rect x="2432.9915" y="685.06781" width="258" height="80.000099" fill-opacity="1" id="rect23" />
                    <rect x="2432.9915" y="524.06781" width="258" height="78.999802" fill-opacity="1" id="rect25" />
                    <path d="m 618.99153,767.0678 35.77,-66 h 314.46 l 35.76997,66 z" fill-rule="evenodd" fill-opacity="1" id="path27" fill="#64748b" />
                    <path d="m 663.99153,673.0678 35.23,-65 h 225.54 l 35.23,65 z" fill-rule="evenodd" fill-opacity="1" id="path29" fill="#64748b" />
                    <path d="m 706.99153,580.0678 35.23,-65 h 140.54 l 35.23,65 z" fill-rule="evenodd" fill-opacity="1" id="path31" fill="#64748b" />
                    <path d="m 750.99153,487.0678 35.77,-66 h 51.46 l 35.77,66 z" fill-rule="evenodd" fill-opacity="1" id="path33" fill="#64748b" />
                    <path d="m 793.99153,394.0678 18,-38 18,38 z" fill-rule="evenodd" fill-opacity="1" id="path35" fill="#64748b" />
                    <path d="m 579.93896,435.9057 -51.77539,49.39795 q -52.83203,-55.73779 -118.87207,-55.73779 -55.73779,0 -94.04101,38.03906 -38.03906,38.03906 -38.03906,93.77685 0,38.83155 16.90625,68.94581 16.90625,30.11425 47.81298,47.28466 30.90674,17.17041 68.68164,17.17041 32.22754,0 58.90772,-11.8872 26.68018,-12.15137 58.64355,-43.85059 l 50.19043,52.30371 q -43.0581,42.00147 -81.36132,58.3794 -38.30323,16.11377 -87.43702,16.11377 -90.60693,0 -148.458,-57.32276 -57.58692,-57.58691 -57.58692,-147.40137 0,-58.11523 26.15186,-103.28662 26.41601,-45.17138 75.28564,-72.64404 49.13379,-27.47266 105.66406,-27.47266 48.07715,0 92.45606,20.34034 44.64307,20.34033 76.8706,57.85107 z" id="path478" />
                    <path d="m 2363.335,435.9057 -51.7754,49.39795 q -52.8321,-55.73779 -118.8721,-55.73779 -55.7378,0 -94.041,38.03906 -38.0391,38.03906 -38.0391,93.77685 0,38.83155 16.9063,68.94581 16.9062,30.11425 47.813,47.28466 30.9067,17.17041 68.6816,17.17041 32.2275,0 58.9077,-11.8872 26.6802,-12.15137 58.6436,-43.85059 l 50.1904,52.30371 q -43.0581,42.00147 -81.3613,58.3794 -38.3033,16.11377 -87.437,16.11377 -90.607,0 -148.458,-57.32276 -57.587,-57.58691 -57.587,-147.40137 0,-58.11523 26.1519,-103.28662 26.416,-45.17138 75.2856,-72.64404 49.1338,-27.47266 105.6641,-27.47266 48.0771,0 92.4561,20.34034 44.643,20.34033 76.8706,57.85107 z" id="path481" />
                </g>
            </g>
        </g>
    </svg>
</template>
