<script setup>
import { ref, onMounted, onUnmounted, watch, inject } from "vue";
import { usePage } from '@inertiajs/vue3';
import { router } from '@inertiajs/vue3';
import moment from "moment/dist/moment";
import fr from "moment/dist/locale/fr";
import { XMarkIcon, Cog8ToothIcon } from '@heroicons/vue/24/outline';
import ListNotifications from "@/Layouts/ListNotifications.vue";
import SecondaryButton from "@/Components/SecondaryButton.vue";

const props = defineProps({
    show: Boolean,
    from: {
        type: String,
        default: "navbar",
    },
});

let gettingNotif = ref(null);
const updatedNotif = inject("updatedNotif");
const emit = defineEmits(["updateCountNotif", "closeNotif"]);

let countAll = ref(0);
let countRead = ref(0);
let notifications = ref({});

onMounted(() => {
    checkNotifications();
    gettingNotif = setInterval(() => checkNotifications(), 600000);
});

onUnmounted(() => {
    clearInterval(gettingNotif);
});

watch(() => props.show, () => {
    if (props.show) {
        axios.get("/user/markread_notifications")
            .then(() => {
                checkNotifications();
            });
    }
}, { deep: true });

watch(() => updatedNotif, () => {
    if (updatedNotif.value != "" && usePage().url.value == "/dashboard" && updatedNotif.value != props.from) {
        checkNotifications();
        updatedNotif.value = "";
    }
}, { deep: true });

const checkNotifications = () => {
    axios.get("/user/notifications")
        .then(({ data }) => {
            countAll.value = data.length;
            countRead.value = 0;
            notifications.value = {};

            data.forEach(element => {
                if (!element.read_at) countRead.value++;

                element.data['notifId'] = element.id;
                element.data['notifType'] = element.type;

                let date = element.created_at.split('T');
                let dateForm = "";
                if (usePage().props.locales.current == "fr") {
                    moment.updateLocale('fr', fr);
                    dateForm = moment(date[0]).format('Do MMMM YYYY');
                } else {
                    moment.locale('en');
                    dateForm = moment(date[0]).format('MMMM Do YYYY');
                }

                if (notifications.value.hasOwnProperty(dateForm)) {
                    notifications.value[dateForm].push(element.data);
                } else {
                    notifications.value[dateForm] = [element.data];
                }
            });

            if (props.from == "navbar") {
                emit("updateCountNotif", countRead.value);
            } else {
                emit("updateCountNotif", countAll.value);
            }
        }).catch((err) => {
            clearInterval(gettingNotif);
        });
};

const closeNotifications = () => {
    emit("closeNotif");
};

const deleteAllNotifications = () => {
    axios.get("/user/delete_notifications")
        .then(() => {
            checkNotifications();
            if (usePage().url.value == "/dashboard") {
                updatedNotif.value = props.from;
            }
        });
};

const deleteNotification = (id) => {
    axios.get("/user/delete_notification/" + id)
        .then(() => {
            checkNotifications();
            if (usePage().url.value == "/dashboard") {
                updatedNotif.value = props.from;
            }
        });
};

const openParameters = () => {
    router.visit('/user/parameters');
};
</script>

<template>
    <div v-if="show && from == 'navbar'" class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden flex justify-end" scroll-region>
        <div class="fixed inset-0" @click="closeNotifications"></div>
        <div class="translate-y-14 -translate-x-16 bg-white rounded-lg overflow-y-auto overflow-hidden shadow-2xl border w-80 h-fit max-h-[calc(100vh-80px)]">
            <div class="sticky top-0 bg-white border-b flex justify-center">
                <p class="text-lg text-gray-700 font-semibold p-1.5">{{ __('Notifications') }}</p>
                <SecondaryButton class="absolute right-2 top-2 !p-1" @click="closeNotifications">
                    <XMarkIcon class="h-4 w-4" />
                </SecondaryButton>
            </div>
            <div class="h-fit text-gray-600 divide-y" v-if="countAll > 0">
                <ListNotifications :notifications="notifications" @deleteNotif="deleteNotification" />
            </div>
            <div class="h-fit text-gray-600 text-center p-5" v-else>
                {{ __('No notification') }}
            </div>
            <div class="sticky bottom-0 bg-white border-t">
                <div class="flex py-1.5 px-2" :class="countAll > 0 ? 'justify-between' : 'justify-end'">
                    <SecondaryButton class="!p-1.5 !text-1.5xs" @click="deleteAllNotifications" v-if="countAll > 0">
                        {{ __('Delete all') }}
                    </SecondaryButton>

                    <SecondaryButton class="!p-1 mr-2" @click="openParameters">
                        <Cog8ToothIcon class="w-4 h-4" />
                    </SecondaryButton>
                </div>
            </div>
        </div>
    </div>
    <div v-else-if="show && from == 'home'" class="max-h-[600px] overflow-hidden overflow-y-auto">
        <div class="flex justify-end px-4 py-1 sticky top-0 bg-white">
            <SecondaryButton class="!p-1.5 !text-1.5xs" @click="deleteAllNotifications" v-if="countAll > 0">
                {{ __('Delete all') }}
            </SecondaryButton>

            <SecondaryButton class="!p-1 ml-2" @click="openParameters">
                <Cog8ToothIcon class="w-4 h-4" />
            </SecondaryButton>
        </div>
        <ListNotifications :notifications="notifications" @deleteNotif="deleteNotification" />
    </div>
</template>
